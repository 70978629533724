<template>
    <div class="con-wrap">
        <CarrotTitle title="Carrot Manual"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <table class="table-row">
                    <colgroup>
                        <col width="180">
                        <col width="*">
                        <col width="180">
                        <col width="200">
                        <col width="180">
                        <col width="200">
                    </colgroup>
                    <tbody>
                        <tr>
                            <td colspan="6">
                                <div class="media-zone-board">
                                    <h2>
                                        
                                        <p class="float-right">
                                            조회수 {{ bbs.info.hit }}
                                            <span> {{ bbs.info.wdate }}</span>
                                        </p>
                                    </h2>
                                    <h3><span class="tag">[{{ bbs.info.category }}] </span> {{ bbs.info.title }}</h3>
                                    <h4>{{ bbs.info.ename }}({{ bbs.info.kname }})</h4>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6" class="contents">
                                <div v-html="bbs.info.content"></div>
                            </td>
                        </tr>
                        <tr v-if="bbs.info.file1_name">
                            <th>파일첨부 #1</th>
                            <td colspan="5">
                              <a class="btn-view" @click.prevent="bbs.downloadFile(bbs.info.file1_url, bbs.info.file1_name)">{{ bbs.info.file1_name }}</a>

                            </td>
                        </tr>
                        <tr v-if="bbs.info.file2_name">
                            <th>파일첨부 #2</th>
                            <td colspan="5">
                              <a class="btn-view" @click.prevent="bbs.downloadFile(bbs.info.file2_url, bbs.info.file2_name)">{{ bbs.info.file2_name }}</a>
                            </td>
                        </tr>
                        <tr v-if="bbs.info.file3_name">
                            <th>파일첨부 #3</th>
                            <td colspan="5">
                              <a class="btn-view" @click.prevent="bbs.downloadFile(bbs.info.file3_url, bbs.info.file3_name)">{{ bbs.info.file3_name }}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div v-if="bbs.isLoaded==true" class="mt-40 comment-list">
                    <div class="title-box type1">
                        <span class="tit" @click="bbs.setGood()">좋아요</span><button class="btn-like" :class="{ 'on':bbs.info.is_good=='Y' }" @click="bbs.setGood()"><span class="num">{{ bbs.info.good }}</span></button><!--좋아요 : on, 해지 : on삭제-->
                        <span class="tit">댓글1</span><span class="num">{{ bbs.info.cnt_cmt }}</span>
                    </div>
                    <carrot-cmt-editor ctype="carrotmanual" :parent="cmt.idx_parent" v-model="bbs.idx" @input="cmt.reloadList()" class="h-150px"></carrot-cmt-editor>
                    <carrot-cmt-list :reload="cmt.isLoadCmtList" ctype="carrotmanual" :index="bbs.idx" v-model:cnt.sync="bbs.info.cnt_cmt"></carrot-cmt-list>
                </div>

                <button class="btn-default float-left mt-30" @click="bbs.showList()">목록</button>
                <button v-if="bbs.isOwner==true||bbs.isAuth==true" class="btn-default float-left mt-30 ml-10" @click="bbs.doDelete()">삭제</button>
                <button v-if="bbs.isOwner==true||bbs.isAuth==true" class="btn-default float-right mt-30" @click="bbs.showMod()">수정</button>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotCmtEditor from '@/components/common/CarrotCmtEditor.vue';
import CarrotCmtList from '@/components/common/CarrotCmtList.vue';


export default {
    layout:"CARROTZone",
    components: {
        CarrotCmtEditor,
        CarrotCmtList,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const toast = useToast()

        const bbs = reactive({
            isOwner  : false,
            isAuth   : false,
            isLoaded : false,

            idx : 0,
            info : {},

            showList: () => {
                router.push({ name:"CARROTZone-CarrotManualList" });
            },
          async downloadFile(url, filename) {
            try {
              const response = await fetch(url);
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const blob = await response.blob();
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = filename;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } catch (error) {
              console.error('There has been a problem with your fetch operation:', error);
            }
          },
            doDelete : () => {
                Swal.fire({
                    title : "Carrot Manual",
                    text: '삭제하시겠습니까?',
                    showCancelButton: true,
                }).then((res) => {
                    if (res.isConfirmed) {
                        let params = {
                            idx : bbs.idx
                        };
                        axios.post("/rest/carrotzone/carrotmanualDel", params).then((res) => {
                            if( res.data.err == 0 ){
                                router.push({
                                    name: 'CARROTZone-CarrotManualList'
                                })
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            showMod: () => {
                router.push({
                    name   : 'CARROTZone-CarrotManualMod-idx',
                    params : { idx:bbs.idx }
                });
            },

            getInfo: () => {
                let params = {
                    idx : bbs.idx
                };
                axios.get("/rest/carrotzone/carrotmanualView", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.info = res.data;

                        bbs.isOwner  = bbs.info.is_owner=='Y'?true:false;
                        bbs.isAuth   = bbs.info.is_auth=='Y'?true:false;
                        bbs.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            setGood: () => {
                let params = {
                    idx : bbs.idx
                };
                axios.get("/rest/carrotzone/carrotmanualGood", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.info.good    = res.data.cnt;
                        bbs.info.is_good = res.data.is_good;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        const cmt = reactive({
            isLoadCmtList : false,
            isMode   : '',
            selected : 0,

            page : 1,
            idx_parent : 0,
            newsmile:"", newtext:"", modsmile:"", modtext:"",
            list : [], total : 0,

            reloadList : () => {
                cmt.isLoadCmtList = false;
                setTimeout(() => {
                    cmt.isLoadCmtList = true;
                }, 100);
            }
        });

        onMounted(() => {
            // Mounted
            bbs.idx = route.params.idx;
            if ( !bbs.idx ) {
                router.go(-1);
            } 
            bbs.getInfo();
            cmt.reloadList();
        });

        onUnmounted(() => { 
            // UnMounted
        });
        return { bbs, cmt };
    }
}
</script>

<style lang="scss" scoped>
.board {
    .contents {
        img {
            max-width : 100%;
        }
    }
}
</style>